/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* ================= */
/* TM CUSTOM BUTTONS */
/* ================= */
/* ================ */
/* TM CUSTOM LABELS */
/* ================ */
.tm-info,
a.tm-info {
  color: #41a6fc;
}
a.tm-info:hover {
  color: #a5d5fe;
  text-decoration: none;
}
.tm-title {
  color: #333333;
  padding: 0px 0px 6px 0px;
  margin: 6px 0px 17px 0px;
  border-bottom: solid #e60502 2px;
}
.tm-subtitle {
  background-color: #eeeeee;
  color: #777777;
  padding: 0.5em;
  border-radius: 3px;
  margin-top: 0px;
}
p > a {
  font-weight: 700;
}
.x-footer > * > p > a {
  font-weight: 300;
}
.navbar-brand > img {
  max-height: 70px !important;
}
@media (max-width: 767px) {
  .navbar {
    min-height: 90px !important;
  }
}
@media (max-width: 376px) {
  .navbar-brand > img {
    max-width: 125px !important;
  }
}
/* ========================== */
/* INCLUDES TM CUSTOM BUTTONS */
/* ========================== */
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-secondary,
.tm-btn-model-baby,
.tm-btn-model-child,
.tm-btn-model-teen,
.tm-btn-model-man,
.tm-btn-model-woman,
.tm-btn-model-pet {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-secondary:active,
.tm-btn-model-baby:active,
.tm-btn-model-child:active,
.tm-btn-model-teen:active,
.tm-btn-model-man:active,
.tm-btn-model-woman:active,
.tm-btn-model-pet:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active,
.btn-secondary.active,
.tm-btn-model-baby.active,
.tm-btn-model-child.active,
.tm-btn-model-teen.active,
.tm-btn-model-man.active,
.tm-btn-model-woman.active,
.tm-btn-model-pet.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default.disabled,
.btn-primary.disabled,
.btn-success.disabled,
.btn-info.disabled,
.btn-warning.disabled,
.btn-danger.disabled,
.btn-secondary.disabled,
.tm-btn-model-baby.disabled,
.tm-btn-model-child.disabled,
.tm-btn-model-teen.disabled,
.tm-btn-model-man.disabled,
.tm-btn-model-woman.disabled,
.tm-btn-model-pet.disabled,
.btn-default[disabled],
.btn-primary[disabled],
.btn-success[disabled],
.btn-info[disabled],
.btn-warning[disabled],
.btn-danger[disabled],
.btn-secondary[disabled],
.tm-btn-model-baby[disabled],
.tm-btn-model-child[disabled],
.tm-btn-model-teen[disabled],
.tm-btn-model-man[disabled],
.tm-btn-model-woman[disabled],
.tm-btn-model-pet[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-secondary,
fieldset[disabled] .tm-btn-model-baby,
fieldset[disabled] .tm-btn-model-child,
fieldset[disabled] .tm-btn-model-teen,
fieldset[disabled] .tm-btn-model-man,
fieldset[disabled] .tm-btn-model-woman,
fieldset[disabled] .tm-btn-model-pet {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge,
.btn-secondary .badge,
.tm-btn-model-baby .badge,
.tm-btn-model-child .badge,
.tm-btn-model-teen .badge,
.tm-btn-model-man .badge,
.tm-btn-model-woman .badge,
.tm-btn-model-pet .badge {
  text-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  background-image: -webkit-linear-gradient(top, #eee 0%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #eee 0%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #eee 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffeeeeee', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #c8c8c8;
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus {
  background-image: -webkit-linear-gradient(top, #eee 0%, #ffffff 100%);
  background-image: -o-linear-gradient(top, #eee 0%, #ffffff 100%);
  background-image: linear-gradient(to bottom, #eee 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffffffff', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-default:active,
.btn-default.active {
  background-color: #cfcfcf;
  border-color: #cacaca;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #cfcfcf;
  background-image: none;
}
.btn-primary {
  background-image: -webkit-linear-gradient(top, #483890 0%, #483890 100%);
  background-image: -o-linear-gradient(top, #483890 0%, #483890 100%);
  background-image: linear-gradient(to bottom, #483890 0%, #483890 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff483890', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #2d2359;
}
.btn-primary:hover,
.btn-primary:focus {
  background-image: -webkit-linear-gradient(top, #483890 0%, #5b47b4 100%);
  background-image: -o-linear-gradient(top, #483890 0%, #5b47b4 100%);
  background-image: linear-gradient(to bottom, #483890 0%, #5b47b4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff5b47b4', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-primary:active,
.btn-primary.active {
  background-color: #322764;
  border-color: #2f245c;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #322764;
  background-image: none;
}
.btn-success {
  background-image: -webkit-linear-gradient(top, #6bb775 0%, #6bb775 100%);
  background-image: -o-linear-gradient(top, #6bb775 0%, #6bb775 100%);
  background-image: linear-gradient(to bottom, #6bb775 0%, #6bb775 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6bb775', endColorstr='#ff6bb775', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #469050;
}
.btn-success:hover,
.btn-success:focus {
  background-image: -webkit-linear-gradient(top, #6bb775 0%, #8dc895 100%);
  background-image: -o-linear-gradient(top, #6bb775 0%, #8dc895 100%);
  background-image: linear-gradient(to bottom, #6bb775 0%, #8dc895 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6bb775', endColorstr='#ff8dc895', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:active,
.btn-success.active {
  background-color: #4b9a55;
  border-color: #489351;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #4b9a55;
  background-image: none;
}
.btn-info {
  background-image: -webkit-linear-gradient(top, #41a6fc 0%, #41a6fc 100%);
  background-image: -o-linear-gradient(top, #41a6fc 0%, #41a6fc 100%);
  background-image: linear-gradient(to bottom, #41a6fc 0%, #41a6fc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff41a6fc', endColorstr='#ff41a6fc', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #0482ed;
}
.btn-info:hover,
.btn-info:focus {
  background-image: -webkit-linear-gradient(top, #41a6fc 0%, #73befd 100%);
  background-image: -o-linear-gradient(top, #41a6fc 0%, #73befd 100%);
  background-image: linear-gradient(to bottom, #41a6fc 0%, #73befd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff41a6fc', endColorstr='#ff73befd', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:active,
.btn-info.active {
  background-color: #058afb;
  border-color: #0484f2;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #058afb;
  background-image: none;
}
.btn-warning {
  background-image: -webkit-linear-gradient(top, #e5b84d 0%, #e5b84d 100%);
  background-image: -o-linear-gradient(top, #e5b84d 0%, #e5b84d 100%);
  background-image: linear-gradient(to bottom, #e5b84d 0%, #e5b84d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5b84d', endColorstr='#ffe5b84d', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #c8961d;
}
.btn-warning:hover,
.btn-warning:focus {
  background-image: -webkit-linear-gradient(top, #e5b84d 0%, #ecca79 100%);
  background-image: -o-linear-gradient(top, #e5b84d 0%, #ecca79 100%);
  background-image: linear-gradient(to bottom, #e5b84d 0%, #ecca79 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5b84d', endColorstr='#ffecca79', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:active,
.btn-warning.active {
  background-color: #d6a01f;
  border-color: #cd991e;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #d6a01f;
  background-image: none;
}
.btn-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #d9534f 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #d9534f 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #d9534f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffd9534f', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #b52b27;
}
.btn-danger:hover,
.btn-danger:focus {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #e27c79 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #e27c79 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #e27c79 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffe27c79', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:active,
.btn-danger.active {
  background-color: #c12e2a;
  border-color: #b92c28;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #c12e2a;
  background-image: none;
}
/* ================= */
/* TM CUSTOM BUTTONS */
.btn-secondary {
  background-image: -webkit-linear-gradient(top, #e60520 0%, #e60520 100%);
  background-image: -o-linear-gradient(top, #e60520 0%, #e60520 100%);
  background-image: linear-gradient(to bottom, #e60520 0%, #e60520 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe60520', endColorstr='#ffe60520', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #9b0316;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-image: -webkit-linear-gradient(top, #e60520 0%, #fa243e 100%);
  background-image: -o-linear-gradient(top, #e60520 0%, #fa243e 100%);
  background-image: linear-gradient(to bottom, #e60520 0%, #fa243e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe60520', endColorstr='#fffa243e', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-secondary:active,
.btn-secondary.active {
  background-color: #aa0418;
  border-color: #a00316;
}
.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-secondary.disabled:hover,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary:hover,
.btn-secondary.disabled:focus,
.btn-secondary[disabled]:focus,
fieldset[disabled] .btn-secondary:focus,
.btn-secondary.disabled.focus,
.btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary.focus,
.btn-secondary.disabled:active,
.btn-secondary[disabled]:active,
fieldset[disabled] .btn-secondary:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary.active {
  background-color: #aa0418;
  background-image: none;
}
.tm-btn-model-baby {
  background-image: -webkit-linear-gradient(top, #ffcc00 0%, #ffcc00 100%);
  background-image: -o-linear-gradient(top, #ffcc00 0%, #ffcc00 100%);
  background-image: linear-gradient(to bottom, #ffcc00 0%, #ffcc00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffcc00', endColorstr='#ffffcc00', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #b38f00;
}
.tm-btn-model-baby:hover,
.tm-btn-model-baby:focus {
  background-image: -webkit-linear-gradient(top, #ffcc00 0%, #ffd633 100%);
  background-image: -o-linear-gradient(top, #ffcc00 0%, #ffd633 100%);
  background-image: linear-gradient(to bottom, #ffcc00 0%, #ffd633 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffcc00', endColorstr='#ffffd633', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-baby:active,
.tm-btn-model-baby.active {
  background-color: #c29b00;
  border-color: #b89300;
}
.tm-btn-model-baby.disabled,
.tm-btn-model-baby[disabled],
fieldset[disabled] .tm-btn-model-baby,
.tm-btn-model-baby.disabled:hover,
.tm-btn-model-baby[disabled]:hover,
fieldset[disabled] .tm-btn-model-baby:hover,
.tm-btn-model-baby.disabled:focus,
.tm-btn-model-baby[disabled]:focus,
fieldset[disabled] .tm-btn-model-baby:focus,
.tm-btn-model-baby.disabled.focus,
.tm-btn-model-baby[disabled].focus,
fieldset[disabled] .tm-btn-model-baby.focus,
.tm-btn-model-baby.disabled:active,
.tm-btn-model-baby[disabled]:active,
fieldset[disabled] .tm-btn-model-baby:active,
.tm-btn-model-baby.disabled.active,
.tm-btn-model-baby[disabled].active,
fieldset[disabled] .tm-btn-model-baby.active {
  background-color: #c29b00;
  background-image: none;
}
.tm-btn-model-child {
  background-image: -webkit-linear-gradient(top, #66cc33 0%, #66cc33 100%);
  background-image: -o-linear-gradient(top, #66cc33 0%, #66cc33 100%);
  background-image: linear-gradient(to bottom, #66cc33 0%, #66cc33 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff66cc33', endColorstr='#ff66cc33', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #478f24;
}
.tm-btn-model-child:hover,
.tm-btn-model-child:focus {
  background-image: -webkit-linear-gradient(top, #66cc33 0%, #85d65c 100%);
  background-image: -o-linear-gradient(top, #66cc33 0%, #85d65c 100%);
  background-image: linear-gradient(to bottom, #66cc33 0%, #85d65c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff66cc33', endColorstr='#ff85d65c', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-child:active,
.tm-btn-model-child.active {
  background-color: #4e9b27;
  border-color: #499325;
}
.tm-btn-model-child.disabled,
.tm-btn-model-child[disabled],
fieldset[disabled] .tm-btn-model-child,
.tm-btn-model-child.disabled:hover,
.tm-btn-model-child[disabled]:hover,
fieldset[disabled] .tm-btn-model-child:hover,
.tm-btn-model-child.disabled:focus,
.tm-btn-model-child[disabled]:focus,
fieldset[disabled] .tm-btn-model-child:focus,
.tm-btn-model-child.disabled.focus,
.tm-btn-model-child[disabled].focus,
fieldset[disabled] .tm-btn-model-child.focus,
.tm-btn-model-child.disabled:active,
.tm-btn-model-child[disabled]:active,
fieldset[disabled] .tm-btn-model-child:active,
.tm-btn-model-child.disabled.active,
.tm-btn-model-child[disabled].active,
fieldset[disabled] .tm-btn-model-child.active {
  background-color: #4e9b27;
  background-image: none;
}
.tm-btn-model-teen {
  background-image: -webkit-linear-gradient(top, #996699 0%, #996699 100%);
  background-image: -o-linear-gradient(top, #996699 0%, #996699 100%);
  background-image: linear-gradient(to bottom, #996699 0%, #996699 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff996699', endColorstr='#ff996699', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #6b476b;
}
.tm-btn-model-teen:hover,
.tm-btn-model-teen:focus {
  background-image: -webkit-linear-gradient(top, #996699 0%, #ad85ad 100%);
  background-image: -o-linear-gradient(top, #996699 0%, #ad85ad 100%);
  background-image: linear-gradient(to bottom, #996699 0%, #ad85ad 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff996699', endColorstr='#ffad85ad', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-teen:active,
.tm-btn-model-teen.active {
  background-color: #744e74;
  border-color: #6e496e;
}
.tm-btn-model-teen.disabled,
.tm-btn-model-teen[disabled],
fieldset[disabled] .tm-btn-model-teen,
.tm-btn-model-teen.disabled:hover,
.tm-btn-model-teen[disabled]:hover,
fieldset[disabled] .tm-btn-model-teen:hover,
.tm-btn-model-teen.disabled:focus,
.tm-btn-model-teen[disabled]:focus,
fieldset[disabled] .tm-btn-model-teen:focus,
.tm-btn-model-teen.disabled.focus,
.tm-btn-model-teen[disabled].focus,
fieldset[disabled] .tm-btn-model-teen.focus,
.tm-btn-model-teen.disabled:active,
.tm-btn-model-teen[disabled]:active,
fieldset[disabled] .tm-btn-model-teen:active,
.tm-btn-model-teen.disabled.active,
.tm-btn-model-teen[disabled].active,
fieldset[disabled] .tm-btn-model-teen.active {
  background-color: #744e74;
  background-image: none;
}
.tm-btn-model-man {
  background-image: -webkit-linear-gradient(top, #336699 0%, #336699 100%);
  background-image: -o-linear-gradient(top, #336699 0%, #336699 100%);
  background-image: linear-gradient(to bottom, #336699 0%, #336699 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff336699', endColorstr='#ff336699', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #204060;
}
.tm-btn-model-man:hover,
.tm-btn-model-man:focus {
  background-image: -webkit-linear-gradient(top, #336699 0%, #407fbf 100%);
  background-image: -o-linear-gradient(top, #336699 0%, #407fbf 100%);
  background-image: linear-gradient(to bottom, #336699 0%, #407fbf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff336699', endColorstr='#ff407fbf', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-man:active,
.tm-btn-model-man.active {
  background-color: #24476b;
  border-color: #214263;
}
.tm-btn-model-man.disabled,
.tm-btn-model-man[disabled],
fieldset[disabled] .tm-btn-model-man,
.tm-btn-model-man.disabled:hover,
.tm-btn-model-man[disabled]:hover,
fieldset[disabled] .tm-btn-model-man:hover,
.tm-btn-model-man.disabled:focus,
.tm-btn-model-man[disabled]:focus,
fieldset[disabled] .tm-btn-model-man:focus,
.tm-btn-model-man.disabled.focus,
.tm-btn-model-man[disabled].focus,
fieldset[disabled] .tm-btn-model-man.focus,
.tm-btn-model-man.disabled:active,
.tm-btn-model-man[disabled]:active,
fieldset[disabled] .tm-btn-model-man:active,
.tm-btn-model-man.disabled.active,
.tm-btn-model-man[disabled].active,
fieldset[disabled] .tm-btn-model-man.active {
  background-color: #24476b;
  background-image: none;
}
.tm-btn-model-woman {
  background-image: -webkit-linear-gradient(top, #990066 0%, #990066 100%);
  background-image: -o-linear-gradient(top, #990066 0%, #990066 100%);
  background-image: linear-gradient(to bottom, #990066 0%, #990066 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff990066', endColorstr='#ff990066', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #4d0033;
}
.tm-btn-model-woman:hover,
.tm-btn-model-woman:focus {
  background-image: -webkit-linear-gradient(top, #990066 0%, #cc0088 100%);
  background-image: -o-linear-gradient(top, #990066 0%, #cc0088 100%);
  background-image: linear-gradient(to bottom, #990066 0%, #cc0088 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff990066', endColorstr='#ffcc0088', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-woman:active,
.tm-btn-model-woman.active {
  background-color: #5c003d;
  border-color: #520036;
}
.tm-btn-model-woman.disabled,
.tm-btn-model-woman[disabled],
fieldset[disabled] .tm-btn-model-woman,
.tm-btn-model-woman.disabled:hover,
.tm-btn-model-woman[disabled]:hover,
fieldset[disabled] .tm-btn-model-woman:hover,
.tm-btn-model-woman.disabled:focus,
.tm-btn-model-woman[disabled]:focus,
fieldset[disabled] .tm-btn-model-woman:focus,
.tm-btn-model-woman.disabled.focus,
.tm-btn-model-woman[disabled].focus,
fieldset[disabled] .tm-btn-model-woman.focus,
.tm-btn-model-woman.disabled:active,
.tm-btn-model-woman[disabled]:active,
fieldset[disabled] .tm-btn-model-woman:active,
.tm-btn-model-woman.disabled.active,
.tm-btn-model-woman[disabled].active,
fieldset[disabled] .tm-btn-model-woman.active {
  background-color: #5c003d;
  background-image: none;
}
.tm-btn-model-pet {
  background-image: -webkit-linear-gradient(top, #00cccc 0%, #00cccc 100%);
  background-image: -o-linear-gradient(top, #00cccc 0%, #00cccc 100%);
  background-image: linear-gradient(to bottom, #00cccc 0%, #00cccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00cccc', endColorstr='#ff00cccc', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #007f80;
}
.tm-btn-model-pet:hover,
.tm-btn-model-pet:focus {
  background-image: -webkit-linear-gradient(top, #00cccc 0%, #00ffff 100%);
  background-image: -o-linear-gradient(top, #00cccc 0%, #00ffff 100%);
  background-image: linear-gradient(to bottom, #00cccc 0%, #00ffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00cccc', endColorstr='#ff00ffff', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.tm-btn-model-pet:active,
.tm-btn-model-pet.active {
  background-color: #008f8f;
  border-color: #008585;
}
.tm-btn-model-pet.disabled,
.tm-btn-model-pet[disabled],
fieldset[disabled] .tm-btn-model-pet,
.tm-btn-model-pet.disabled:hover,
.tm-btn-model-pet[disabled]:hover,
fieldset[disabled] .tm-btn-model-pet:hover,
.tm-btn-model-pet.disabled:focus,
.tm-btn-model-pet[disabled]:focus,
fieldset[disabled] .tm-btn-model-pet:focus,
.tm-btn-model-pet.disabled.focus,
.tm-btn-model-pet[disabled].focus,
fieldset[disabled] .tm-btn-model-pet.focus,
.tm-btn-model-pet.disabled:active,
.tm-btn-model-pet[disabled]:active,
fieldset[disabled] .tm-btn-model-pet:active,
.tm-btn-model-pet.disabled.active,
.tm-btn-model-pet[disabled].active,
fieldset[disabled] .tm-btn-model-pet.active {
  background-color: #008f8f;
  background-image: none;
}
/* ================= */
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
  background-color: #e8e8e8;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: -webkit-linear-gradient(top, #483890 0%, #3f317d 100%);
  background-image: -o-linear-gradient(top, #483890 0%, #3f317d 100%);
  background-image: linear-gradient(to bottom, #483890 0%, #3f317d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff3f317d', GradientType=0);
  background-repeat: repeat-x;
  background-color: #3f317d;
}
.navbar-default {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
.navbar-inverse {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 3px;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #292929 0%, #2f2f2f 100%);
  background-image: -o-linear-gradient(top, #292929 0%, #2f2f2f 100%);
  background-image: linear-gradient(to bottom, #292929 0%, #2f2f2f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff292929', endColorstr='#ff2f2f2f', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(top, #483890 0%, #3f317d 100%);
    background-image: -o-linear-gradient(top, #483890 0%, #3f317d 100%);
    background-image: linear-gradient(to bottom, #483890 0%, #3f317d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff3f317d', GradientType=0);
    background-repeat: repeat-x;
  }
}
.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.alert-success {
  background-image: -webkit-linear-gradient(top, #e0f0e2 0%, #c6e3ca 100%);
  background-image: -o-linear-gradient(top, #e0f0e2 0%, #c6e3ca 100%);
  background-image: linear-gradient(to bottom, #e0f0e2 0%, #c6e3ca 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe0f0e2', endColorstr='#ffc6e3ca', GradientType=0);
  background-repeat: repeat-x;
  border-color: #add7b2;
}
.alert-info {
  background-image: -webkit-linear-gradient(top, #ddeffe 0%, #b7ddfe 100%);
  background-image: -o-linear-gradient(top, #ddeffe 0%, #b7ddfe 100%);
  background-image: linear-gradient(to bottom, #ddeffe 0%, #b7ddfe 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffddeffe', endColorstr='#ffb7ddfe', GradientType=0);
  background-repeat: repeat-x;
  border-color: #92ccfd;
}
.alert-warning {
  background-image: -webkit-linear-gradient(top, #f8edd3 0%, #f4e0b1 100%);
  background-image: -o-linear-gradient(top, #f8edd3 0%, #f4e0b1 100%);
  background-image: linear-gradient(to bottom, #f8edd3 0%, #f4e0b1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8edd3', endColorstr='#fff4e0b1', GradientType=0);
  background-repeat: repeat-x;
  border-color: #efd390;
}
.alert-danger {
  background-image: -webkit-linear-gradient(top, #f9e2e1 0%, #f2c3c2 100%);
  background-image: -o-linear-gradient(top, #f9e2e1 0%, #f2c3c2 100%);
  background-image: linear-gradient(to bottom, #f9e2e1 0%, #f2c3c2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9e2e1', endColorstr='#fff2c3c2', GradientType=0);
  background-repeat: repeat-x;
  border-color: #eba4a2;
}
.progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar {
  background-image: -webkit-linear-gradient(top, #e60520 0%, #b40419 100%);
  background-image: -o-linear-gradient(top, #e60520 0%, #b40419 100%);
  background-image: linear-gradient(to bottom, #e60520 0%, #b40419 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe60520', endColorstr='#ffb40419', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #6bb775 0%, #4ea159 100%);
  background-image: -o-linear-gradient(top, #6bb775 0%, #4ea159 100%);
  background-image: linear-gradient(to bottom, #6bb775 0%, #4ea159 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6bb775', endColorstr='#ff4ea159', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-info {
  background-image: -webkit-linear-gradient(top, #41a6fc 0%, #0f8efb 100%);
  background-image: -o-linear-gradient(top, #41a6fc 0%, #0f8efb 100%);
  background-image: linear-gradient(to bottom, #41a6fc 0%, #0f8efb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff41a6fc', endColorstr='#ff0f8efb', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #e5b84d 0%, #dfa621 100%);
  background-image: -o-linear-gradient(top, #e5b84d 0%, #dfa621 100%);
  background-image: linear-gradient(to bottom, #e5b84d 0%, #dfa621 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5b84d', endColorstr='#ffdfa621', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #362a6b;
  background-image: -webkit-linear-gradient(top, #483890 0%, #3b2e74 100%);
  background-image: -o-linear-gradient(top, #483890 0%, #3b2e74 100%);
  background-image: linear-gradient(to bottom, #483890 0%, #3b2e74 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff3b2e74', GradientType=0);
  background-repeat: repeat-x;
  border-color: #3b2e74;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #483890 0%, #3f317d 100%);
  background-image: -o-linear-gradient(top, #483890 0%, #3f317d 100%);
  background-image: linear-gradient(to bottom, #483890 0%, #3f317d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff483890', endColorstr='#ff3f317d', GradientType=0);
  background-repeat: repeat-x;
}
.panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #e0f0e2 0%, #cfe8d2 100%);
  background-image: -o-linear-gradient(top, #e0f0e2 0%, #cfe8d2 100%);
  background-image: linear-gradient(to bottom, #e0f0e2 0%, #cfe8d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe0f0e2', endColorstr='#ffcfe8d2', GradientType=0);
  background-repeat: repeat-x;
}
.panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #ddeffe 0%, #c4e3fe 100%);
  background-image: -o-linear-gradient(top, #ddeffe 0%, #c4e3fe 100%);
  background-image: linear-gradient(to bottom, #ddeffe 0%, #c4e3fe 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffddeffe', endColorstr='#ffc4e3fe', GradientType=0);
  background-repeat: repeat-x;
}
.panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f8edd3 0%, #f5e4bc 100%);
  background-image: -o-linear-gradient(top, #f8edd3 0%, #f5e4bc 100%);
  background-image: linear-gradient(to bottom, #f8edd3 0%, #f5e4bc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8edd3', endColorstr='#fff5e4bc', GradientType=0);
  background-repeat: repeat-x;
}
.panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f9e2e1 0%, #f4cdcc 100%);
  background-image: -o-linear-gradient(top, #f9e2e1 0%, #f4cdcc 100%);
  background-image: linear-gradient(to bottom, #f9e2e1 0%, #f4cdcc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9e2e1', endColorstr='#fff4cdcc', GradientType=0);
  background-repeat: repeat-x;
}
.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}
/*# sourceMappingURL=bootstrap-theme.css.map */